export const firebaseConfig = {
    apiKey: "AIzaSyA9DyuQD8fNS_3ywxEGWQXTI",
    authDomain: "torqueue-8e871.firebaseapp.com",
    databaseURL: "https://torqueue-8e871-default-rtdb.firebaseio.com",
    projectId: "torqueue-8e871",
    storageBucket: "torqueue-8e871.appspot.com",
    messagingSenderId: "278604385987",
    appId: "1:278604385987:web:16466bd0cb5dc422f69c76",
    measurementId: "G-XSSGCWGN76",
};
